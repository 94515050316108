@if (urlIcon) {
  <div
    [class]="
      'inline-flex aspect-square w-4 items-center justify-center ' +
      (className || '')
    ">
    <img [src]="urlIcon" alt="icon" class="aspect-square w-full object-cover" />
  </div>
} @else {
  <div class="flex items-center justify-center">
    <mat-icon
      [fontIcon]="fontIcon || ''"
      [svgIcon]="svgIcon || ''"
      [className]="className || ''"
      [fontSet]="fontSet || ''"
      >{{ iconName || '' }}</mat-icon
    >
  </div>
}
